import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import banner from "../../../../staticAssets/Horoscope Bg.png";
import Aries from "../../../../staticAssets/Aries.png";
import love from "../../../../staticAssets/love.png";
import finance from "../../../../staticAssets/finance.png";
import business from "../../../../staticAssets/business.png";
import wealth from "../../../../staticAssets/wealth.png";

import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../../context/Auth";
import { AnalyticsContext } from "../../../../context/Analytics";

import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet-async";

function Index({location}) {
  const { site } = useStaticQuery(
    graphql`
      query  {
        site {
          siteMetadata {
            seos {
              title
              description
              canonicalUrl
              path
              _xv
            }
          }
        }
      }
    `
  );
  const [horoscope, sethoroscope] = useState("");
const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
const { user } = useContext(AuthContext) || {
  user: {},
};
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    fn();
  }, []);
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://api.bodhiness.com/v1/horoscope/mesh/Monthly?time=today&language=hindi&categories=finance"
      );

      if (res.data.statusCode == 200) {
        // setblogs([...res.data]);
        sethoroscope(res?.data?.payLoad?.info);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Helmet>
        <meta
          name="title"
          content={`${
            site.siteMetadata.seos.filter((value) => {
              return (
                value.path ==
                location.pathname.slice(0,-1)
              );
            })[0]?.title
          }`}
        />
        <meta
          name="description"
          content={`${
            site.siteMetadata.seos.filter((value) => {
              return (
                value.path ==
                location.pathname.slice(0,-1)
              );
            })[0]?.description
          }`}
        />
        <link
          rel="canonical"
          href={`${
            site.siteMetadata.seos.filter((value) => {
              return (
                value.path ==
                location.pathname.slice(0,-1)
              );
            })[0]?.canonicalUrl
          }`}
        />
      </Helmet>

      <Header />
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
        
          <div className="w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 w-screen md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                
                मेष मासिक धन और वित्त राशिफल
              </h1>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="container mx-auto flex flex-wrap my-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer ml-4"
              >
                <h4 className="text-orange-600 text-sm mx-1">Bodhi</h4>
              </a>
              <h4 className="mx-1 text-sm">/</h4>
              <a
                href="https://meesho.bodhiness.com/hindi/rashifal/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm mx-1">Rashifal</h4>
              </a>
              <h4 className="mx-1 text-sm">/</h4>
              <a
                href="https://meesho.bodhiness.com/hindi/rashifal/monthly-money-and-finanace-rashifal/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm mx-1">Monthly Money And Finance Rashifal
</h4>
              </a>
              <h4 className="mx-1 text-sm">/</h4>
              <a
                href="https://meesho.bodhiness.com/hindi/rashifal/monthly-rashifal/mesh/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm mx-1">Mesh</h4>
              </a>
            </div>
            <div className="container mx-auto flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={Aries} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">
                  <h1 className="font-semibold text-xl md:text-4xl ml-4">
                    मेष
                  </h1>
                  <h2 className="text-sm md:text-base ml-4">{(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)).toLocaleDateString('en-GB') + " - " + (new Date((new Date()).getFullYear(), (new Date()).getMonth()+1, 0)).toLocaleDateString('en-GB')}</h2>
                </div>
                <div className=""></div>
              </div>
            </div>
            <div className="container mx-auto">
              <div className="flex gap-6 overflow-x-scroll py-4 hide-scroll-bar scrollbar-hide">
                <a className="flex flex-nowrap" href="https://meesho.bodhiness.com/hindi/rashifal/daily-money-and-finance-rashifal/mesh/">
                  <button className="bg-white px-4 md:px-6 py-0.5 md:py-1 text-gray-400 border border-gray-400 rounded-lg text-sm md:text-lg ml-4">
                    आज
                  </button>
                </a>
                <a className="flex flex-nowrap" href="https://meesho.bodhiness.com/hindi/rashifal/tomorrow-money-and-finance-rashifal/mesh/">
                  <button className="bg-white px-4 md:px-6 py-0.5 md:py-1 text-gray-400 border border-gray-400 rounded-lg text-sm md:text-lg">
                    अगामी दिवस
                  </button>
                </a>
                <a className="flex flex-nowrap" href="https://meesho.bodhiness.com/hindi/rashifal/yesterday-money-and-finance-rashifal/mesh/">
                  <button className="bg-white px-4 md:px-6 py-0.5 md:py-1 text-gray-400 border border-gray-400 rounded-lg text-sm md:text-lg">
                    पूर्व दिवस
                  </button>
                </a>
                <a className="flex flex-nowrap" href="https://meesho.bodhiness.com/hindi/rashifal/weekly-money-and-finance-rashifal/mesh/">
                  <button className="bg-white px-4 md:px-6 py-0.5 md:py-1 text-gray-400 border border-gray-400 rounded-lg text-sm md:text-lg">
                    साप्ताहिक
                  </button>
                </a>
                <a className="flex flex-nowrap" href="https://meesho.bodhiness.com/hindi/rashifal/monthly-money-and-finance-rashifal/mesh/">
                  <button className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] px-4 md:px-6 py-0.5 md:py-1 text-white rounded-lg text-sm md:text-lg">
                    मासिक
                  </button>
                </a>
              </div>
            </div>
            <div className="container mx-auto my-4 md:my-10">
              <h6 className="tracking-wide mx-4">{horoscope ?? ""}</h6>
            </div>

            <h4 className="text-[36px] text-center font-semibold text-black playfair my-10">
              अन्य मासिक राशिफल
            </h4>
            <div className="flex justify-center mb-20">
              <div className="grid grid-cols-1 md:grid-cols-2 w-10/12 md:w-2/3 gap-10">
                <a className="cursor-pointer rounded-xl flex flex-col shadow p-6 border border-orange-800/20 bg-yellow-100/20" href="https://meesho.bodhiness.com/hindi/rashifal/monthly-love-and-relationship-rashifal/mesh">
                  <div className="flex items-center">
                    <div className="h-12 w-12 bg-yellow-100/60 p-2 rounded-xl flex items-center justify-center">
                      <img src={love} className="object-contain" />
                    </div>
                    <h6 className="font-semibold ml-4">
                      मेष मासिक प्रेम और सबं धं राशि फल
                    </h6>
                  </div>
                  <div className="bg-orange-800/20 rounded-full h-[1px] my-4"></div>
                  <span>
                    प्रेम मनष्ुय के जीवन की आधारशि ला है, और बोधि की प्रेम कंुडली हृदय रेखा की प्रवत्तिृत्तियों को समझनेकी कोशि श करती हैऔर कि ए जानेवालेकार्यों का एक वि चारोत्तेजक पाठ्यक्रम देती है... {" "}
                    <a className="cursor-pointer text-[#FB5B1D]" href="https://meesho.bodhiness.com/hindi/rashifal/monthly-love-and-relationship-rashifal/mesh">और पढ़ें</a>...
                  </span>
                </a>
                <a className="cursor-pointer rounded-xl flex flex-col shadow p-6 border border-blue-800/20 bg-blue-100/20" href="https://meesho.bodhiness.com/hindi/rashifal/monthly-health-rashifal/mesh">
                  <div className="flex items-center">
                    <div className="h-12 w-12 bg-blue-100/60 p-2 rounded-xl flex items-center justify-center">
                      <img src={wealth} className="object-contain" />
                    </div>
                    <h6 className="font-semibold ml-4">
                      मेष मासिक स्वास्थ्य राशिफल
                    </h6>
                  </div>
                  <div className="bg-blue-800/20 rounded-full h-[1px] my-4"></div>
                  <span>
                    मासिक स्वास्थ्य राशिफल आपको अपने स्वास्थ्य को फलने-फूलने में मदद करेगा और आपको अपने आप में फिट रखेगा अपने मासिक स्वास्थ्य राशिफल पर बोधि की समीक्षा पढ़ें,... {" "}
                    <a className="cursor-pointer text-[#FB5B1D]" href="https://meesho.bodhiness.com/hindi/rashifal/monthly-health-rashifal/mesh">और पढ़ें</a>...
                  </span>
                </a>
                <a className="cursor-pointer rounded-xl flex flex-col shadow p-6 border border-blue-800/20 bg-blue-100/20" href="https://meesho.bodhiness.com/hindi/rashifal/monthly-money-and-finance-rashifal/mesh">
                  <div className="flex items-center">
                    <div className="h-12 w-12 bg-blue-100/60 p-2 rounded-xl flex items-center justify-center">
                      <img src={finance} className="object-contain" />
                    </div>
                    <h6 className="font-semibold ml-4">
                      मेष मासिक धन और वि त्त राशि फल
                    </h6>
                  </div>
                  <div className="bg-blue-800/20 rounded-full h-[1px] my-4"></div>
                  <span>
                    बचाया हुआ पसै ा कमाया हुआ पसै ा है, बोधि का मौद्रि क/वि त्तीय राशि फल आपको धन प्रबधं न और अधि कतमकरण पर समय-समय पर अतं र्दृष्टि देता है... {" "}
                    <a className="cursor-pointer text-[#FB5B1D]" href="https://meesho.bodhiness.com/hindi/rashifal/monthly-money-and-finance-rashifal/mesh">और पढ़ें</a>...
                  </span>
                </a>
                <a className="cursor-pointer rounded-xl flex flex-col shadow p-6 border border-orange-800/20 bg-yellow-100/20" href="https://meesho.bodhiness.com/hindi/rashifal/monthly-career-and-business-rashifal/mesh">
                  <div className="flex items-center">
                    <div className="h-12 w-12 bg-yellow-100/60 p-2 rounded-xl flex items-center justify-center">
                      <img src={business} className="object-contain" />
                    </div>
                    <h6 className="font-semibold ml-4">
                      मेष मासिक करि यर और व्यवसाय राशि फल
                    </h6>
                  </div>
                  <div className="bg-orange-800/20 rounded-full h-[1px] my-4"></div>
                  <span>
                    करि यर पेशेऔर पढ़ाई के मामलेमेंव्यक्ति के व्यक्ति त्व को सामनेलाता है। बोधि में, हम लोगों को उनके लि ए सबसेउपयक्ुत भमिूमिका या करि यर पथ खोजनेमेंमदद करनेके लि ए कंुडली प्रदान करतेहैं...{" "}
                    <a className="cursor-pointer text-[#FB5B1D]" href="https://meesho.bodhiness.com/hindi/rashifal/monthly-career-and-business-rashifal/mesh">और पढ़ें</a>...
                  </span>
                </a>
              </div>
            </div>
          </div>
        <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
</main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
